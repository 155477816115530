import MicroModal from 'micromodal'
import LazyLoad from 'vanilla-lazyload'
import Notifications from '@/components/notifications'
import * as Sentry from '@sentry/browser'
import './components/accordion'

import { closeDropdowns, openDropdown } from './components/dropdown'
import { addEventListenerList, debounce, normalizeTime } from './components/helpers'
import { setCookie } from './components/cookie'

const Body = document.querySelector('body')
document.addEventListener('DOMContentLoaded', async () => {
  Body.classList.add('loaded')
})

const NotificationsResponse = document.querySelector('#notifications-response')
if (NotificationsResponse) {
  new Notifications({
    title: NotificationsResponse.getAttribute('data-title'),
    content: NotificationsResponse.getAttribute('data-content'),
    type: NotificationsResponse.getAttribute('data-type'),
  }).show()
  NotificationsResponse.remove()
}

/**
 * Sentry.io
 */
Sentry.init({
  dsn: 'https://23ac45a30ea3768673444614cbc99698@o4508162988638208.ingest.us.sentry.io/4508162990014464',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  // Capture 100% of the transactions
  tracesSampleRate: 1.0,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: [document.location.origin],
  // Session Replay
  // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysSessionSampleRate: 0.1,
  // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  replaysOnErrorSampleRate: 1.0,
})

/**
 * Modal Functionality
 */
const microModalConfig = {
  openTrigger: 'data-modal',
  openClass: 'shown',
  closeTrigger: 'data-modal-close',
  disableScroll: true,
  disableFocus: true,
  awaitOpenAnimation: true,
  awaitCloseAnimation: true,
}
MicroModal.init(microModalConfig)

window.openModal = (modalId = '') => {
  if (!isModalOpened(modalId)) MicroModal.show(modalId, microModalConfig)
}

window.closeModal = (modalId = '') => {
  if (isModalOpened(modalId)) MicroModal.close(modalId)
}

const isModalOpened = (modalId) => {
  const modal = document.getElementById(modalId)
  return modal.getAttribute('aria-hidden') === 'false'
}

document.addEventListener('DOMContentLoaded', () => {
  const ResponseModal = document.querySelector('#modal-response')
  if (ResponseModal) MicroModal.show('modal-response', microModalConfig)
})

/**
 * Lazyload
 */
new LazyLoad({
  restore_on_error: true,
})

/**
 * iOS hack for focusing input
 */
const HiddenInput = document.createElement('input')
HiddenInput.style.position = 'absolute'
HiddenInput.style.opacity = '0'
document.body.appendChild(HiddenInput)

/**
 * Search Bar Functionality
 */
const SearchBarTriggers = document.querySelectorAll('[data-modal="modal-search"]')
const SearchInput = document.getElementById('search-input')
const SearchResults = document.getElementById('search-results')

if (SearchBarTriggers.length) {
  addEventListenerList(SearchBarTriggers, 'click', async () => {
    HiddenInput.focus()
    await preloadBrands()
    SearchInput.removeAttribute('disabled')
    SearchInput.focus()
  })

  const preloadBrands = async () => {
    if (window.searchItems) return

    const brands = await fetch('/api/brands').then((response) => response.json())
    window.searchItems = brands
  }

  const debounced = debounce(async ({ target: { value } }) => {
    const brands = await getBrands(value)
    processSearchQuery(brands)
  }, 333)

  SearchInput.addEventListener('input', (e) => {
    // Delete existed nodes
    SearchResults.innerHTML = ''
    window.searchFirstItem = null
    debounced(e)
  })

  SearchInput.addEventListener('keyup', (e) => {
    if (e.key === 'Enter') {
      const firstItem = window.searchFirstItem
      if (firstItem) window.location.replace(`/brands/${firstItem.slug}`)
    }
  })
}

const getBrands = (query = '') => {
  const value = query.replace(/[^a-zA-Z0-9]/gi, '').toLowerCase()
  if (!value) return window.searchItems.slice(0, 6)

  const brands = window.searchItems
    .filter((b) => b.q.indexOf(value) > -1)
    .sort((a, b) => {
      const startsWithA = a.q.startsWith(value)
      const startsWithB = b.q.startsWith(value)

      if (startsWithA && !startsWithB) return -1
      if (!startsWithA && startsWithB) return 1

      return 0
    })

  return brands.slice(0, 6)
}

const processSearchQuery = (items = []) => {
  window.searchFirstItem = items[0]
  // Delete existed nodes
  SearchResults.innerHTML = ''

  // Create new nodes
  if (items.length) {
    items.map((item) => {
      const html = `<li><a class="search-results-item" href="/brands/${item.slug}"><img src="${item.image_url}" alt="${item.title}" /><div><span>${item.categories}</span><h4>${item.title}</h4></div></a></li>`
      SearchResults.innerHTML += html
    })
  } else {
    const html = `<li><p class="search-results-none">Sorry, no brands found matching your search query</p></li>`
    SearchResults.innerHTML += html
  }
}

/**
 * Banner Functionality
 */
const Banner = document.querySelector('#banner')
if (Banner) {
  const BannerClose = Banner.querySelector('#banner-close')

  BannerClose.addEventListener('click', (e) => {
    e.preventDefault()
    setCookie('banner_id', Banner.getAttribute('data-id'), 2147483647)
    Banner.remove()
  })
}

/**
 * Dropdown Functionality
 */
const DropdownTogglers = document.querySelectorAll('[data-dropdown]')
Body.addEventListener('click', () => closeDropdowns())
addEventListenerList(DropdownTogglers, 'click', ({ target }) => openDropdown(target))
DropdownTogglers.forEach((Toggler) => {
  if (Toggler.hasAttribute('data-shown')) openDropdown(Toggler)
})

/**
 * Phone Number Mask
 */
const PhoneInputs = document.querySelectorAll('input[type=tel]')
if (PhoneInputs.length) {
  PhoneInputs.forEach((input) => {
    return input.addEventListener('input', function (e) {
      var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
      e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')
    })
  })
}

/**
 * Aside toggler
 */
const Aside = document.querySelector('#aside')

if (Aside) {
  const AsideToggler = document.querySelector('#aside-toggler')
  const AsideOverlay = document.querySelector('#aside-overlay')

  AsideToggler.addEventListener('click', () => toggleAside())
  AsideOverlay.addEventListener('click', () => toggleAside())

  const toggleAside = () => {
    Aside.classList.toggle('shown')
    AsideOverlay.classList.toggle('shown')
  }
}

/**
 * Header Cart Timer
 */
const HeaderCartTimer = document.querySelector('#header-cart-timer')
if (HeaderCartTimer) {
  const cartTimerEndDate = HeaderCartTimer.getAttribute('data-expired')

  const cartTimerTick = () => {
    let now = Math.round(new Date().getTime() / 1000)
    let secondsLeft = cartTimerEndDate - now

    // Fallback for ended carts
    // TODO: Rewrite it?
    if (secondsLeft < 0) {
      clearInterval(cartTimerInterval)
      HeaderCartTimer.remove()
      return
    }

    if (secondsLeft >= 0) {
      var secondsToWarn = 60 * 2
      if (secondsLeft < secondsToWarn && !HeaderCartTimer.classList.contains('ending')) {
        HeaderCartTimer.classList.add('ending')
        new Notifications({
          title: 'Your cart is about to expire',
          content: 'Checkout within the next 2 minutes to ensure that you can get the cards in your shopping cart',
          type: 'notice',
        }).show()
      }

      HeaderCartTimer.innerHTML = normalizeTime(secondsLeft)
    } else {
      HeaderCartTimer.classList.remove('ending')
      clearInterval(cartTimerInterval)
      window.location.reload()
    }
  }

  const cartTimerInterval = setInterval(cartTimerTick, 1000)
  cartTimerTick()
}
